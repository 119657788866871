import React, { useEffect, useState, useRef } from 'react';
import Icon from '../Icon';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';

function ReadMore(props) {
  const [initialHeight, setInitialHeight] = useState(160);
  const [maxHeight, setMaxHeight] = useState(160);
  const [hideReadMore, setHideReadMore] = useState(false);

  const container = useRef();

  useEffect(() => {
    // FIXME Why does it need this timeout to calculate the correct height? Can we listen for the div to change size instead?
    setTimeout(() => {
      updateCalculatedHeight();
    }, 1000);
    updateCalculatedHeight();
    if (props.initialHeight) {
      setInitialHeight(props.initialHeight);
      setMaxHeight(props.initialHeight);
    }
    window.addEventListener('resize', updateCalculatedHeight);
    return () => {
      window.removeEventListener('resize', updateCalculatedHeight);
    };
  }, []);

  const toggle = () => {
    // If expanded, close it
    if (maxHeight !== initialHeight) return setMaxHeight(initialHeight);

    const height = calculateHeight();

    // set the full height
    return setMaxHeight(height);
  };

  const updateCalculatedHeight = () => {
    if (container) {
      const calculatedHeight = calculateHeight();
      console.log('Container height:');
      console.log(calculatedHeight);
      if (calculatedHeight <= initialHeight) {
        setHideReadMore(true);
      } else {
        setHideReadMore(false);
      }
    }
  };

  // FIXME This isn't calculating the correct height
  const calculateHeight = () => {
    // calculate height of all the children
    // const { children } = container;
    console.log('Children:');
    console.log(container.current);
    let height = 0;
    if (container && container.current) {
      console.log(container.current.children);
      [container.current.children[0]].forEach((child) => {
        // console.log('Child object:');
        // console.log(child);
        // console.log(child.clientHeight);
        // console.log(child.offsetHeight);
        if (child.clientHeight > 0) {
          height += child.clientHeight;
        }
      });
    }
    return height;
  };

  const { children, readMore, blurStyle, overhangSize } = props;
  const open = maxHeight !== initialHeight;

  return (
    <>
      <div
        className="readmore container"
        style={{
          padding: 0,
          maxHeight: open ? maxHeight : maxHeight - overhangSize,
          transition: 'max-height .5s ease',
          position: 'relative',
          overflow: 'hidden',
        }}
        ref={container}
      >
        {children}
        {hideReadMore ? null : (
          <div
            className="readmore overhang"
            style={{
              transition: 'opacity 0.25s',
              opacity: open ? 0 : 1,
              backgroundImage:
                props.settings.night_mode
                  ? 'linear-gradient(to bottom, rgba(36, 38, 42, 0.44), #24262a )'
                  : 'linear-gradient(to bottom, rgba(255, 255, 255, 0.44), #ffffff )',
              content: '',
              height: `${overhangSize}px`,
              width: '100%',
              position: 'absolute',
              bottom: '0',
              left: '0',
              ...blurStyle,
            }}
          />
        )}
      </div>
      {hideReadMore ? null : (
        <Button onClick={toggle}>
          {open ? 'Read Less' : 'Read More'}
          <Icon
            name="chevron-right"
            style={{
              transform: `rotate( ${open ? '-90deg' : '90deg'})`,
              transition: 'transform 0.25s',
            }}
          />
        </Button>
      )}
    </>
  );
}

ReadMore.defaultProps = {
  overhangSize: 80,
};

function mapStateToProps(state) {
  return {
    settings: state.get('settings'),
  };
}

export default connect(mapStateToProps)(ReadMore);
