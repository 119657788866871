import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Linkify from 'linkify-react';
import { withRouter } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import Hashids from 'hashids/cjs';
import { Spinner } from 'reactstrap';
import UrlPreview from '../url-preview';
import VideoSource from '../VideoSource';
import { config } from '../../settings';
import { customEmojiForShortname } from '../PostActionIcons';
const hashids = new Hashids('', 12);

const ReactDOMServer = require('react-dom/server');

// FIXME Try to get this working
// const renderLink = ({ attributes, content }) => {
//   const { href } = attributes;
//   if (/\.(?:png|jpg|jpeg|gif|svg)/.test(content)) {
//     return content;
//   }
//   return <a href={href}>{content}</a>;
// };
// const linkify = (text) => ReactDOMServer.renderToStaticMarkup(<Linkify options={{ render: renderLink }}>{text}</Linkify>);
const linkify = (text) => ReactDOMServer.renderToStaticMarkup(<Linkify>{text}</Linkify>);

// TODO Find an example to test this on - not sure exactly how long/short we want to limit it to
// const limitText = (text) => text ? text.splice(0, 250) : '';
const limitText = (text) => text;

export const sanitizeText = (text) => {
  const sanitizeConf = {
    // parser: {
    //   decodeEntities: true,
    // },
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'h1', 'img'],
    allowedClasses: {
      img: ['emoji'],
    },
    allowedAttributes: {
      a: ['href', 'data-*'],
      img: ['src', 'style'],
    },
    // FIXME Can we also allow our own custom emojis? - https://www.npmjs.com/package/sanitize-html
    // allowedIframeHostnames: [
    //   'www.youtube.com',
    //   'www.vimeo.com',
    //   'www.spotify.com',
    //   'open.spotify.com',
    //   'www.giphy.com',
    //   'embed.giphy.com',
    // ],
  };
  // console.log(sanitizeHtml(text, sanitizeConf));
  // FIXME I would prefer the solution above - this shouldn't remove links from the entire text just because there is
  //  an image included in the content - this is good temporarily
  if (/\.(?:png|jpg|jpeg|gif|svg)/.test(text)) {
    return sanitizeHtml(text, sanitizeConf);
  }
  return linkify(sanitizeHtml(text, sanitizeConf)).replace('&amp;', '&'); // FIXME Why is it necessary to decode the html like this?
};

const getGalleryHtml = (url) => `<a class="rui-gallery-item" data-keyboard="true" data-touch="true" data-close-existing="true" data-auto-focus="true" href="${url}" data-fancybox="comment-gallery"><img src="${url}" style="width: 100%" /></a>`;

function isValidUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

function RichCard(props) {
  const [meta, setMeta] = useState(props.meta);
  const [loadingMeta, setLoadingMeta] = useState(false);
  const [iframe, setIframe] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [content, setContent] = useState('');

  // https://stackoverflow.com/questions/48025373/dangerouslysetinnerhtml-and-link
  // FIXME tagName does not exist on the empty target object - why is target empty?
  const navigate = (e) => {
    // e.preventDefault();
    e.persist();
    console.log('Testing for anchor element...');
    const target = e.target || e.srcElement;
    // console.log(JSON.stringify(target));
    if (target.tagName === 'A') {
      console.log('Found anchor element!');
      const href = target.getAttribute('href');
      if (href) {
        console.log('Found href!');
        event.preventDefault();
        // FIXME We can take this a step further - if the URL domain matches the current domain, push
        if (isValidUrl) {
          if (typeof props.onNavigate === 'function') {
            props.onNavigate(href);
          }
          window.open(href, '_blank');
        } else {
          props.history.push(href);
        }
      }
    }
  };

  const onClick = (url) => {
    if (typeof props.onNavigate === 'function') {
      props.onNavigate(url);
    }
  };

  const parseContent = (c) => {
    let parsedContent = c;
    // FIXME This all can probably be simplified
    const regex = parsedContent.match(/(\[((@|~)[a-zA-Z0-9&+\s\(\)\"\.]+)\|([a-zA-Z0-9\s]+:\d+)\])/g);
    const emojiRegex = parsedContent.match(/:(\D+):/g);

    if (regex) {
      console.log('regex:');
      console.log(regex);
      // FIXME Strip out invalid chars like ' or #? Or include them in the regex? These don't match when the name has special chars
      for (const fullMatch of regex) {
        console.log('fullMatch:');
        console.log(fullMatch);
        if (fullMatch.indexOf('userId') !== -1) {
          const userRegex = fullMatch.match(/(@[a-zA-Z0-9&+\s\.]+)|(userId:\d+)/g);
          if (userRegex.length) {
            console.log('userRegex:');
            console.log(userRegex);
            // FIXME There has to be a way to use react-router instead of anchor tags for these - I don't like that it reloads the page when clicking these
            parsedContent = parsedContent.replace(fullMatch, `<a href="/users/${hashids.encode(parseInt(userRegex[1].replace('userId:', '')))}"><strong>${userRegex[0].replace('@', '')}</strong></a>`);
          }
        }

        if (fullMatch.indexOf('venueId') !== -1) {
          const venueRegex = fullMatch.match(/(@[a-zA-Z0-9&+\s\.]+)|(venueId:\d+)/g);
          if (venueRegex.length) {
            parsedContent = parsedContent.replace(fullMatch, `<a href="/venues/${hashids.encode(parseInt(venueRegex[1].replace('venueId:', '')))}"><strong>${venueRegex[0].replace('@', '')}</strong></a>`);
          }
        }

        if (fullMatch.indexOf('brandId') !== -1) {
          const brandRegex = fullMatch.match(/(@[a-zA-Z0-9&+\s\.]+)|(brandId:\d+)/g);
          if (brandRegex.length) {
            parsedContent = parsedContent.replace(fullMatch, `<a href="/brands/${hashids.encode(parseInt(brandRegex[1].replace('brandId:', '')))}"><strong>${brandRegex[0].replace('@', '')}</strong></a>`);
          }
        }

        if (fullMatch.indexOf('cigarId') !== -1) {
          const cigarRegex = fullMatch.match(/((@|~)[a-zA-Z0-9&+\s\.]+)|(cigarId:\d+)/g);
          if (cigarRegex.length) {
            console.log('cigarRegex:');
            console.log(cigarRegex);
            parsedContent = parsedContent.replace(fullMatch, `<a href="/cigars/${hashids.encode(parseInt(cigarRegex[1].replace('cigarId:', '')))}"><strong>${cigarRegex[0].replace('@', '')}</strong></a>`);
          }
        }

        if (fullMatch.indexOf('productId') !== -1) {
          const productRegex = fullMatch.match(/(@[a-zA-Z0-9&+\s\.\(\)\"\.]+)|(productId:\d+)/g);
          if (productRegex.length) {
            console.log('productRegex:');
            console.log(productRegex);
            parsedContent = parsedContent.replace(fullMatch, `<a href="/products/${hashids.encode(parseInt(productRegex[1].replace('productId:', '')))}"><strong>${productRegex[0].replace('@', '')}</strong></a>`);
          }
        }
      }
    }

    if (emojiRegex && emojiRegex.length) {
      console.log('emojiRegex:');
      console.log(emojiRegex);
      const emoji = customEmojiForShortname(emojiRegex[0].replace(/:/g, ''));
      console.log('Selected Emoji:');
      console.log(emoji);
      if (emoji && emoji.imageUrl) {
        parsedContent = parsedContent.replace(emojiRegex[0], `<img src="${emoji.imageUrl}" class="emoji" data-shortname="${emoji.colons}" />`);
      }
    }
    return parsedContent;
  };

  useEffect(() => {
    const { children } = props;
    const innerText = children.toString(); // FIXME Do we have to stringify it?
    console.log('Comment content:');
    console.log(JSON.stringify(innerText));
    const urlMatcher = '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?';
    const urlPattern = new RegExp(urlMatcher);
    const imgPattern = new RegExp('<img([\\w\\W]+?)/>');
    const emailPattern = new RegExp('[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+');
    // FIXME This should all be cached in the db and pulled in with the posts for better loading - phase this out. The code below
    //  should only be used for the initial time a user posts a link in a comment so they can see how it will be rendered
    if (props.meta) {
      if (!imgPattern.test(innerText) && urlPattern.test(innerText)) {
        const url = innerText.match(urlMatcher)[0];
        setContent(innerText.replace(url, ''));
      }
      if (props.meta.ogVideo && props.meta.ogVideo.url) {
        setVideo(props.meta.ogVideo);
        setVideoThumbnail(props.meta.ogImage);
      }
    } else if (!props.hideMeta) {
      if (!emailPattern.test(innerText) && !imgPattern.test(innerText) && urlPattern.test(innerText)) {
        setLoadingMeta(true);
        console.log('Text has URL...');
        const url = innerText.match(urlMatcher)[0];
        console.log(innerText.match(urlMatcher));
        if (url.indexOf('spotify') !== -1) {
          setIframe(<iframe src={url} width="100%" height="80" frameBorder="0" allowTransparency="true" allow="encrypted-media" />);
          setContent(innerText.replace(url, ''));
          setLoadingMeta(false);
        } else {
          axios.get(`${config.mediaEndPoint}/meta`, {
            params: { url },
          }).then((response) => {
            console.log(response);
            if (url.indexOf('youtube') !== -1 || url.indexOf('vimeo') !== -1) {
              setVideo(response.data.ogVideo);
              setVideoThumbnail(response.data.ogImage);
            }
            // FIXME Set these on the store so they can be reused instead of pulled again - probably just map them to the URL
            setMeta(response.data);
            setContent(innerText.replace(url, ''));
            setLoadingMeta(false);
          }).catch((err) => {
            console.log(`Failed to get meta data for ${url}`);
            console.log(err); // Do anything?
            // FIXME This should really get checked first - no need for the meta call, it just slows things down
            if (url.indexOf('.png') !== -1 || url.indexOf('.jpg') !== -1 || url.indexOf('.jpeg') !== -1 || url.indexOf('.gif') !== -1) {
              setContent(innerText.replace(url, getGalleryHtml(url)));
            } else {
              setContent(innerText);
            }
            setLoadingMeta(false);
          });
        }
      } else if (imgPattern.test(innerText)) {
        const original = innerText.match('<img([\\w\\W]+?)/>')[0];
        let url = innerText.match('<img([\\w\\W]+?)/>')[1];
        url = url.replace('src="', '').replace('"', '').replace('{boxpressd_cdn}', config.cdnUrl).trim();
        setContent(innerText.replace(original, getGalleryHtml(url)));
      } else {
        setContent(innerText.replace('{boxpressd_cdn}', config.cdnUrl));
      }
    } else {
      setContent(innerText.replace('{boxpressd_cdn}', config.cdnUrl));
    }
  }, [props.children]);

  if (loadingMeta && !meta) {
    return (
      <div
        style={{
          textAlign: 'center',
          margin: 10,
        }}
      >
        <Spinner
          color="secondary"
          type="grow"
        >
          {'Loading...'}
        </Spinner>
      </div>
    );
  }
  return (
    <div className="rich-content" onClick={navigate}>
      <div dangerouslySetInnerHTML={{ __html: parseContent(sanitizeText(limitText(content))) }} />
      {(meta && !video) ? <UrlPreview {...meta} compact={props.compact} onClick={onClick} /> : null}
      {iframe}
      {video && (
        <VideoSource
          videoSrc={video.url}
          // scaleWidth={304}
          scaleHeight={244}
          placeholder={videoThumbnail && videoThumbnail.url}
          title={meta && meta.ogTitle}
        />
      )}
    </div>
  );
}

export default withRouter(RichCard);
