import React, { useState, useEffect, useFocus } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import { isIOS } from 'mobile-device-detect';
import MobileSearchBar, { useStyles } from '../MobileSearchBar';
import ModalView from '../ModalView';
import Icon from '../Icon';
import { toWordCase } from '../../utils/formatting';
import ChipInput from 'material-ui-chip-input';
import IconButton from '@material-ui/core/IconButton';

function SearchPicker(props) {
  const classes = useStyles();
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState(null);

  const [chips, setChips] = useState([]);

  useEffect(() => {
    setResults(props.results);
  }, [props.results]);

  // Patch to fix issue in BXPR-154
  let iosStyles = {};
  if (isIOS) {
    iosStyles = {
      display: 'block',
      height: 56,
      paddingTop: 1,
    };
  }
  return (
    <ModalView
      title={props.title}
      open={props.open}
      showFrom="bottom"
      hideAppBarShadow
      onClick={props.onClose}
      onClose={props.onClose}
    >
      {!props.multiple && (
        <MobileSearchBar
          id="search-picker-bar"
          inputId="search-picker-bar-input"
          inputRef={(ref) => {
            console.log('Setting new ref...');
            console.log(ref);
            if (ref && typeof ref.focus === 'function') {
              console.log('Updating focus on new ref...');
              ref.focus();
            }
          }}
          placeholder={props.searchPlaceholder}
          onQueryChange={(q) => {
            setQuery(q);
            if (typeof props.onQueryChange === 'function') {
              props.onQueryChange(q);
            }
          }}
        />
      )}
      {props.multiple && (
        <AppBar
          id={props.id || 'mobile-search-bar'}
          position="static"
          color="white"
          style={{ ...props.style, ...iosStyles }}
        >
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <Icon name="search" style={{ zIndex: 1 }} />
            </div>
            <ChipInput
              id="search-picker-bar-multi-input"
              inputRef={(ref) => {
                if (ref && typeof ref.focus === 'function') {
                  console.log('Updating focus on new ref...');
                  ref.focus();
                }
              }}
              allowDuplicates={false}
              // blurBehavior="add"
              blurBehavior="ignore"
              placeholder={props.searchPlaceholder}
              classes={{ root: classes.inputRoot }}
              style={{ width: '100%', paddingLeft: 50 }}
              value={chips}
              onAdd={(chip) => {
                setChips([...chips, chip]);
              }}
              onDelete={(chip, index) => {
                chips.splice(index, 1);
                setChips([...chips]);
              }}
              onUpdateInput={(e) => {
                if (typeof props.onQueryChange === 'function') {
                  props.onQueryChange(e.target.value);
                }
              }}
            />
            <IconButton
              aria-label=""
              edge="end"
              color="inherit"
              style={{
                width: 64,
                padding: 6,
                float: 'right',
                marginRight: 5,
                height: 40,
                fontSize: '1rem',
              }}
              onClick={() => {
                if (typeof props.onSelect === 'function') {
                  props.onSelect(chips.map((chip) => ({ value: chip, label: chip })), chips.join(', '));
                }
              }}
            >
              {'Done'}
            </IconButton>
          </div>
        </AppBar>
      )}
      <List>
        {results.map((result) => (
          <ListItem
            alignItems="flex-start"
            onClick={() => {
              if (props.multiple) {
                setChips([...chips, result]);
              } else {
                props.onSelect({ value: result, label: result });
              }
            }}
          >
            <ListItemText primary={result} />
          </ListItem>
        ))}
        {props.create && query && (
          <ListItem
            alignItems="flex-start"
            onClick={() => {
              if (props.multiple) {
                setChips([...chips, toWordCase(query)]);
              } else {
                props.onSelect({ label: toWordCase(query), value: toWordCase(query) });
              }
            }}
          >
            <Icon name="plus" style={{ marginTop: 3, marginRight: 10 }} />
            <ListItemText primary={`Create "${toWordCase(query)}"`} />
          </ListItem>
        )}
      </List>
    </ModalView>
  );
}

SearchPicker.propTypes = {
  searchPlaceholder: PropTypes.string,
  onQueryChange: PropTypes.func,
  onSelect: PropTypes.func,
  results: PropTypes.array.isRequired,
  create: PropTypes.bool,
};

SearchPicker.defaultProps = {
  searchPlaceholder: 'Search...',
  onQueryChange: () => {},
  onSelect: () => {},
  create: false,
};

export default SearchPicker;
