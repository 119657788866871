import React, { PureComponent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import './style.scss';
import { Link, withRouter } from 'react-router-dom';
import Hashids from 'hashids/cjs';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Icon from '../Icon';
import Placeholder from '../../../config/placeholder.config';
import { formatDuration, renderTimestamp } from '../../utils/formatting';
import BottomSheet from '../bottom-sheet';
import { saveItem } from '../../pages/SavedItems/helper';
import ShareIntent from '../../utils/shareUtils';
import ShareItem from '../../models/ShareItem';
import ModalDialog from '../ModalDialog';
import { language, messages } from '../../utils/localeUtils';
import { addToast as actionAddToast } from '../../actions';
import { deleteSession as deleteSessionAction } from '../../pages/SessionEditor/actions';
import FlavorNotesChart from '../flavor-notes-chart';
import Drink from '../../models/Drink';
import { Resize } from '../../utils/imageUtils';
import Venue from '../../models/Venue';
const hashids = new Hashids('', 12);

function SessionNotesCard({ session, basic, auth, history, addToast, deleteSession }) {
  const sessionImage = () => {
    if (session.media && session.media.length) {
      for (let i = 0; i < session.media.length; i++) {
        const m = session.media[i];
        if (m.media_type === 'image') {
          // setMetaImage(`${config.mediaEndPoint}/meta/images/thumbnails/${m.media_url}`);
          return Resize.size(m.media_url);
        }
      }
    }
    if (session.scan && session.scan.image_url) {
      // setMetaImage(`${config.mediaEndPoint}/meta/images/thumbnails/${session.scan.image_url}`);
      return Resize.size(session.scan.image_url);
    }
    return undefined;
  };

  const [moreOptions, setMoreOptions] = useState([{
    content: 'Mark as Favorite',
    icon: 'heart',
    value: 'favorite',
    onClick: () => {
      const { cigar } = session.scan;
      const { user } = auth;
      saveItem(user, 'cigar', cigar, 'favorites');
      addToast({
        content: (<>{`Marked '${cigar.full_name}' as Favorite`}</>),
        duration: 6000,
      });
    },
  }, {
    content: 'Show Where To Buy',
    icon: 'shopping-cart',
    value: 'products',
    onClick: () => {
      const { cigar } = session.scan;
      history.push({ pathname: `/cigars/${hashids.encode(cigar.id)}?tab=products`, state: { cigar } });
    },
  }, {
    content: 'Share',
    icon: 'share',
    value: 'share',
    onClick: () => {
      const { cigar } = session.scan;
      ShareIntent.share(new ShareItem({
        title: session.scan.cigar.full_name,
        text: session.comment,
        path: 'cigar',
        route: `/cigars/${hashids.encode(session.scan.cigar.id)}?sessionId=${hashids.encode(session.id)}`,
        image: sessionImage(),
      }));
    },
  }]);

  useEffect(() => {
    // FIXME Can this be simplified?
    if ((session && session.scan && session.scan.user && session.scan.user.id) === (auth && auth.user && auth.user.id)) {
      setMoreOptions((prevOptions) => [
        ...prevOptions, {
          content: 'Edit Session',
          icon: 'edit',
          value: 'edit',
          onClick: () => {
            const { cigar } = session.scan;
            history.push({
              pathname: `/cigars/${hashids.encode(cigar.id)}`,
              search: `action=smokenow&itemId=${cigar.id}&editId=${session.id}`,
              state: { session },
            });
          },
        }, {
          content: 'Delete Session',
          icon: 'trash',
          value: 'delete',
          style: {
            color: '#ef5164',
          },
          onClick: () => {
            ModalDialog.show({
              title: 'Delete Session',
              message: 'Are you sure you want to delete this smoke session?',
              buttons: [{
                label: messages[language]?.cancel || 'Cancel',
                onClick: () => {
                  ModalDialog.close();
                },
              }, {
                label: 'Delete',
                style: { color: 'rgb(239, 81, 100)' },
                onClick: () => {
                  deleteSession(session);
                  ModalDialog.close();
                },
              }],
              onClose: () => {
                ModalDialog.close();
              },
            });
          },
        }]);
    }
  }, []);

  const activeRating = () => {
    const rating = session.average_rating;

    if (typeof rating !== 'undefined' && rating !== null) {
      if (rating.rating !== null) {
        rating.overall = rating.rating; // INFO The API needs updated
      }

      if (typeof rating.flavor === 'undefined') {
        rating.flavor = rating.overall;
        rating.draw = rating.overall;
        rating.burn = rating.overall;
        rating.appearance = rating.overall;
      }
    }

    return (typeof rating !== 'undefined' && rating !== null) ? rating : {};
  };

  const getImage = () => {
    const { media } = session;
    if (media && media.length > 0) {
      for (let i = 0; i < session.media.length; i++) {
        const m = session.media[i];
        if (m.media_type === 'image') {
          return m.media_url.replace('/sessions/', '/sessions/100x100/');
        }
      }
    }
    if (session.image_url) {
      return session.image_url;
    }
    if (session.scan.image_url) {
      return session.scan.image_url;
    }
    return Placeholder.cigar;
  };

  const renderRatingRow = (title, value) => (
    <table style={{ width: '100%' }}>
      <tbody>
        <tr>
          <td>{title}</td>
          <td>
            <div className="rating">
              <Rating
                initialRating={value}
                emptySymbol="far fa-star"
                fullSymbol="fas fa-star"
                fractions={2}
                readonly
                style={{ color: 'rgb(214, 194, 144)' }}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );

  const renderComment = () => (
    <div style={{ display: 'flex', marginTop: 10, marginLeft: 16, marginRight: 16 }}>
      <Icon name={['fas', 'quote-left']} vendor="fa" style={{ color: '#d9d9d9', width: 24 }} />
      <div style={{ flex: 'auto', marginLeft: 6, marginBottom: 10 }}>
        {session.comment || 'No comments for this session'}
      </div>
    </div>
  );

  const renderPurchaseLocation = () => {
    const purchaseLocation = session.bought_from;
    return (
      <div style={{ display: 'flex', marginTop: 8 }}>
        {purchaseLocation && (
          <Link to={`/venues/${purchaseLocation.hash_id}`} style={{ display: 'flex' }}>
            <Avatar src={Venue.getImage(purchaseLocation)} style={{ height: 20, width: 20, marginRight: 10, marginLeft: 16 }} alt={purchaseLocation.name}>{purchaseLocation.name.charAt(0)}</Avatar>
            <div style={{ flex: 'auto' }}>
              Bought at
              {' '}
              {purchaseLocation.name}
            </div>
          </Link>
        )}
        {!purchaseLocation && <span style={{ marginLeft: 16 }}>No purchase info added</span>}
      </div>
    );
  };

  const renderSmokeLocation = () => {
    const smokeLocation = session.smoke_venue;
    return (
      <div style={{ display: 'flex', marginTop: 8 }}>
        {smokeLocation && (
          <Link to={`/venues/${smokeLocation.hash_id}`} style={{ display: 'flex' }}>
            <Avatar src={Venue.getImage(smokeLocation)} style={{ height: 20, width: 20, marginRight: 10, marginLeft: 16 }} alt={smokeLocation.name}>{smokeLocation.name.charAt(0)}</Avatar>
            <div style={{ flex: 'auto' }}>
              Smoked at
              {' '}
              {smokeLocation.name}
            </div>
          </Link>
        )}
        {!smokeLocation && <span style={{ marginLeft: 16 }}>No smoke location added</span>}
      </div>
    );
  };

  const renderDrinkPairing = () => {
    const drinkPairing = session.drink_pairing;
    return (
      <div style={{ display: 'flex', marginTop: 8, marginBottom: 20 }}>
        {drinkPairing && (
          <>
            <Avatar src={Drink.getImage(drinkPairing)} style={{ height: 20, width: 20, marginRight: 10, marginLeft: 16 }} alt={drinkPairing.name}>{drinkPairing.name.charAt(0)}</Avatar>
            <div style={{ flex: 'auto' }}>
              Paired with
              {' '}
              {drinkPairing.name}
            </div>
          </>
        )}
        {!drinkPairing && <span style={{ marginLeft: 16 }}>No drink pairing added</span>}
      </div>
    );
  };

  return (
    <span>
      <div style={{ padding: 10 }}>
        <div className="session-notes-card">
          <div style={{ display: 'flex' }}>
            <div>
              <div style={{ display: 'flex' }}>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="More Options"
                  style={{ marginRight: 2, position: 'absolute', right: 5 }}
                  onClick={() => {
                    BottomSheet.show({
                      items: moreOptions,
                    });
                  }}
                >
                  <Icon name="more-horizontal" style={{ height: 22, width: 22 }} />
                </IconButton>

                <div style={{ textAlign: 'center', paddingTop: 22, width: 100 }}>
                  {/* TODO Still use image grid here? Or circle crop of first image? */}
                  <img
                    src={getImage(session)}
                    title={session.scan.cigar.full_name}
                    alt={session.scan.cigar.full_name}
                    style={{
                      boxShadow: '0 0 0 5px rgba(0,0,0,.1),0 0 10px hsla(0,0%,100%,.2)',
                      borderRadius: '50%',
                      height: 80,
                      width: 80,
                      objectFit: 'cover',
                    }}
                    onError={(e) => { e.target.onerror = null; e.target.src = Placeholder.cigar; }}
                  />
                </div>

                {basic && (
                  <div style={{ marginTop: 10, padding: 20, flex: 1, minWidth: 185 }}>
                    <div>{`Smoked ${renderTimestamp(session.smoked_timestamp || session.timestamp)}${session.duration ? `${formatDuration(session.duration)}` : ''}`}</div>
                    <Rating
                      initialRating={(session.advance_rating && session.advance_rating.rating) || 0}
                      emptySymbol="far fa-star"
                      fullSymbol="fas fa-star"
                      fractions={2}
                      readonly
                      style={{
                        color: 'rgb(214, 194, 144)',
                        marginBottom: 10,
                      }}
                    />
                    {session.vitola && <p>{session.vitola.formatted_name}</p>}
                    {session.buy_again && <div>Would buy again</div>}
                    {!session.buy_again && <div>Would NOT buy again</div>}
                  </div>
                )}

                {!basic && (
                  <div style={{ padding: 20 }}>
                    <div>
                      <span style={{ fontSize: 30, marginRight: 20 }}>
                        {(session.scan.cigar.avg_rating || 0).toFixed(0)}
                        <span style={{ fontSize: 12 }}>/ 100</span>
                      </span>
                      <span>{`Based on ${session.scan.cigar.total_ratings} ratings`}</span>
                      {session.buy_again && <div>Would buy again</div>}
                      {!session.buy_again && <div>Would NOT buy again</div>}
                    </div>

                    <div style={{ padding: 12 }}>
                      <div className="row" style={{ marginTop: 5 }}>
                        {renderRatingRow('Flavor', activeRating().flavor)}
                        {renderRatingRow('Burn', activeRating().burn)}
                        {renderRatingRow('Draw', activeRating().draw)}
                        {renderRatingRow('Appearance', activeRating().appearance)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              { renderComment()}
              { renderPurchaseLocation() }
              { renderSmokeLocation() }
              { renderDrinkPairing() }
            </div>
            {session.note && (
              <div
                style={{
                  padding: '20px 0px',
                  marginRight: 16,
                }}
              >
                <FlavorNotesChart notes={session.note} legend={false} height={140} width={140} />
              </div>
            )}
          </div>
        </div>
      </div>
    </span>
  );
}

SessionNotesCard.propTypes = {
  session: PropTypes.object.isRequired,
  basic: PropTypes.bool,
};

SessionNotesCard.defaultProps = {
  basic: true,
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (data) => dispatch(actionAddToast(data)),
    deleteSession: (session) => dispatch(deleteSessionAction(session)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SessionNotesCard));
